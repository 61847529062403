import { DAILY_EARNING_GRAPH_DAYS_COUNT } from "@/constants";

const TIME_FORMAT = /(\d{2}):(\d{2}):(\d{2})/; // To check for time in the format HH:MM:SS

export function pad(value: number, string?: string) {
  return ((string || "0") + value).slice(-2);
}

export function hhmm(secs: number) {
  let minutes = Math.floor(secs / 60);
  secs = secs % 60;
  const hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)} Hrs ${pad(minutes)} Mins`;
}

export const withoutTime = (date: Date) => {
  const result = new Date(date);

  result.setHours(0);
  result.setMinutes(0);
  result.setSeconds(0);
  result.setMilliseconds(0);

  return result;
};

export const fullDate = (date: string) => {
  const parsedDate = new Date(date);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const day = parsedDate.getDate();
  const monthIndex = parsedDate.getMonth();
  const year = parsedDate.getFullYear();
  const hours = parsedDate.getHours();
  const minutes = parsedDate.getMinutes();

  function getOrdinalSuffix(day: number): string {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  }

  return `${months[monthIndex]} ${getOrdinalSuffix(day)}, ${year}, ${hours}:${String(
    minutes
  ).padStart(2, "0")} UTC`;
};

export const toStandardisedDuration = (value: string | null | undefined) => {
  if (typeof value !== "string") {
    return "-- hrs -- mins";
  }

  const input = value.toLowerCase();
  let hours = 0;
  let minutes = 0;
  let days = 0;
  let result = "";

  const timeMatch = input.match(TIME_FORMAT);
  if (timeMatch) {
    hours = parseInt(timeMatch[1], 10);
    minutes = parseInt(timeMatch[2], 10);
  }

  const hourMatch = input.match(/(\d+)\s*hrs?/i);
  const minuteMatch = input.match(/(\d+)\s*mins?/i);
  const dayMatch = input.match(/(\d+)\s*days?/i);

  if (hourMatch) {
    hours += parseInt(hourMatch[1], 10);
  }

  if (minuteMatch) {
    minutes += parseInt(minuteMatch[1], 10);
  }

  if (dayMatch) {
    days = parseInt(dayMatch[1], 10);
  } else if (hours >= 24) {
    days = Math.floor(hours / 24);
    hours = hours % 24;
  }

  if (days > 0) {
    result += `${days} day${days > 1 ? "s" : ""} `;
  }

  result += `${hours} hr${hours !== 1 ? "s" : ""} `;
  result += `${minutes} min${minutes !== 1 ? "s" : ""}`;

  return result.trim();
};

export const removeZeroPrefix = (value: string) => {
  if (value.slice(0, 1) === "0") {
    return value.slice(1);
  }
  return value;
};

// returns in MMM dd, YYYY hh:mm:ss UTC format
// example - Apr 30, 2024 15:46:32 UTC
export const formatDateTime = (dateTime: unknown) => {
  if (typeof dateTime !== "string") {
    return;
  }

  return `${formatDate(dateTime)} ${formatTime(dateTime)}`;
};

export const formatDate = (dateTime: unknown) => {
  if (typeof dateTime !== "string") {
    return "-";
  }
  const parsedDate = new Date(normalizedDateTime(dateTime));
  return parsedDate.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric"
  });
};

export const formatTime = (dateTime: string) => {
  if (typeof dateTime !== "string") {
    return "-";
  }
  const parsedDate = new Date(normalizedDateTime(dateTime));
  const formatedTime = parsedDate.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });
  return `${formatedTime} UTC`;
};

export const normalizedDateTime = (dateTime: string) => {
  let normalizedDateTime = dateTime.replace(" ", "T");

  // Append time if only date is provided
  if (normalizedDateTime.length === 10) {
    normalizedDateTime += "T00:00:00";
  }

  return normalizedDateTime;
};

export const timeUntil = (date: Date, intervalTypes?: string[]) => {
  const seconds = Math.floor((date.getTime() - new Date().getTime()) / 1000);
  return formatSeconds(seconds, intervalTypes);
};

export const timeSince = (date: Date, intervalTypes?: string[]) => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  return formatSeconds(seconds, intervalTypes);
};

const formatSeconds = (seconds: number, intervalTypes?: string[]) => {
  let interval = seconds / 31536000;

  if (!Array.isArray(intervalTypes)) {
    intervalTypes = ["y", "w", "d", "h", "m", "s"];
  }

  if (interval <= 0) {
    return "now";
  }

  if (interval > 1 && intervalTypes.includes("y")) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 648000;
  if (interval > 1 && intervalTypes.includes("w")) {
    return Math.floor(interval) + " weeks";
  }
  interval = seconds / 86400;
  if (interval > 1 && intervalTypes.includes("d")) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1 && intervalTypes.includes("h")) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1 && intervalTypes.includes("m")) {
    return Math.floor(interval) + " minutes";
  }
  if (intervalTypes.includes("s")) {
    return Math.floor(seconds) + " seconds";
  }
  return "";
};

export const toDuration = (interval: number) => {
  if (typeof interval !== "number") {
    return;
  }

  let delta = Math.abs(interval) / 1000;

  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  const minutes = Math.round(delta / 60);
  const seconds = Math.round(delta);

  const result = `${days} days ${hours} hours ${minutes} minutes`;

  if (days === 0 && hours === 0 && minutes === 0) {
    return `${result} ${seconds} seconds`;
  }

  return result;
};

export const getDurationDates = (durationType: string) => {
  const currentDate = new Date();
  const endDate = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate())
  );
  let startDate;
  if (durationType === "daily") {
    startDate = new Date(
      Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate() - DAILY_EARNING_GRAPH_DAYS_COUNT
      )
    );
  } else {
    startDate = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() - 11, 1));
  }

  return [startDate.toISOString().split("T")[0], endDate.toISOString().split("T")[0]];
};
